//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: transparent;
	padding: 2.222rem 0 1rem 0;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;

	&.hero {
		background-color: transparent;

		#logo {

			.sticky-img {
				display: none;
				opacity: 0;
				transition: all .2s linear;
			}
		}
		
		#burger-menu-trigger {
			color: white;
		}

		#header-extras {
			background-color: transparent;

			.extra {
				color: white;
			}
		}

		&.sticky {
			background-color: white;

			#logo {
				max-width: 7rem;

				img {
					opacity: 0;
					display: none;

					&.sticky-img {
						opacity: 1;
						display: initial;
					}
				}
			}
		}
	}

	&.sticky {
		padding: 1rem 0;
		background-color: white;

		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		#burger-menu-trigger {
			color: $color-text;

			&::before {
				content: url('../images/menu-vector-sticky.svg');
			}
		}
	}

	#burger-menu-trigger {
		font-size: $regular;
		color: $color-text;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-family: $font-title;
		text-transform: lowercase;
		gap: 10px;
		position: relative;
		padding: $small 0;
		background-color: transparent;
		cursor: pointer;

		&::before {
			content: url('../images/menu-vector.svg');
			position: absolute;
			z-index: -1;
			width: auto;
			height: 100%;
			left: 55%;
			top: 55%;
			transform: translate(-50%, -50%);
		}

		.trigger-bar {
			display: block;
			width: 25px;
			height: 3px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-pink;
			border-radius: 5px;
			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:767px) {
			padding: 11px 0;

			&::before {
				left: 50%;
				content: url('../images/menu-vector-mobile.svg') !important;
			}

			.title {
				display: none;
				visibility: hidden;
			}
		}
	}

	#logo {
		float: left;
		width: 100%;
		max-width: 10rem;
		a {
			display: block;
		}

		@media (max-width:767px) {
			max-width: 7rem;
		}

		.sticky-img {
			display: none;
			opacity: 0;
			transition: all .2s linear;
		}
	}

	#search-action {
		.input-group {
			display: flex;
			.form-control {
				width: 250px;
			}
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	#menu {
		transition: all 0.4s linear;
		float: right;
		position: relative;
		margin-right: 60px;

		@media (max-width:767px) {
			margin-right: 40px;
		}
	}

	#header-extras {
		position: absolute;
		top: -2.222rem;
		right: 24px;
		transition: all 0.4s linear;
		margin-top: 0;
		background-color: $color-pink-light;
		font-family: $font-title;
		font-size: 14px;
		display: flex;
		align-items: center;
		justify-content: flex-end;

		@media (max-width:767px) {
			display: none;
			visibility: hidden;
		}

		.extra {
			display: inline-block;
			color: $color-text;
			text-transform: uppercase;
			padding: 4px $small;
			&:last-child {
				padding-right: $medium;
				border-bottom-right-radius: 4px;
			}

			&:first-child {
				padding-left: $medium;
				border-bottom-left-radius: 4px;
			}

			&:hover {
				background-color: $color-pink;
				color: white;

				.current {

					svg {
						path {
							fill: white !important;
						}
					}
				}
			}
		}

		#language-switcher {
			position: relative;

			.current {
				display: flex;
				align-items: center;
				justify-content: space-between;
				gap: 6px;
			}

			.languages-list {
				margin: 0;
				padding: 0;
				list-style: none;
				position: absolute;
				left: 0;
				top: 33px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				overflow: hidden;
				opacity: 0;
				visibility: hidden;

				li {
					display: inline-block;
					text-transform: uppercase;
					display: block;
					color: white;

					a {
						color: inherit;
						padding: 4px 16px;
						padding-right: 1.83rem;
						background-color: $color-pink;
						display: inline-block;
					}

					&:hover {

						a {
							background-color: $color-pink-dark;
						}
					}
				}
			}

			&:hover {
				border-radius: 0 !important;

				.current {

					svg {
						path {
							fill: $color-text;
						}
					}
				}

				.languages-list {
					opacity: 1;
					visibility: visible;
				}
			}
		}
	}
}


//burger menu
.mobile-nav {
	position: fixed;
	top: 0;
	right: -100vw;
	z-index: 1;
	background-color: $color-pink;
	color: white;
	width: 100vw;
	max-width: 35vw;
	height: 100vh;
	padding: 0 $huge;
	padding-top: $big;
	padding-bottom: $regular;
	transition: all .4s ease-in-out;

	@media (max-width:1440px) {
		
		.first-level {
			font-size: 2rem !important;
		}

		.second-level-container {
			font-size: 1.6rem !important;
		}
	}

	@media (max-width:991px) {
		max-width: 55vw;
	}

	@media (max-width:767px) {
		max-width: 100vw;
		padding: $big;
		padding-bottom: $regular;

		.first-level {
			font-size: 1.8rem !important;
		}

		.second-level-container {
			font-size: 1.45rem !important;
		}

		.second-level {
			margin-top: 12px;
		}

		.arrow {
			max-width: $regular;
		}

		.top-part {

			.logo {
				visibility: visible !important;
			}

			// .close {
			// 	max-height: $big;
			// }
		}

		.bottom-part {
			display: flex !important;
			visibility: visible !important;
		}
	}

	&.is-open {
		right: 0;
	}

	a {
		color: inherit;
		width: 100%;
		display: inline-block;

		&:hover {
			color: $color-pink-light;
		}
	}

	.top-part {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 3rem;

		.logo {
			max-width: 7rem;
			visibility: hidden;
		}
		
		.close {
			height: $large;
			width: auto;
			cursor: pointer;
			transition: all .2s linear;
	
			&:hover {
				opacity: .6;
			}
		}
	}

	.bottom-part {
		background-color: $color-pink-light;
		color: $color-text;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		align-items: center;
		justify-content: space-between;
		padding: 0 $big;
		text-transform: uppercase;
		display: none;
		visibility: hidden;
		font-size: 15px;
		line-height: normal;

		#language-switcher {

			.languages-list {
				margin: 0;
				padding: 0;
				list-style: none;
				position: absolute;
				right: 0;
    		bottom: 100%;;
				border-top-left-radius: 4px;
				overflow: hidden;
				opacity: 0;
				visibility: hidden;

				li {
					display: inline-block;
					text-transform: uppercase;
					display: block;
					color: $color-text;

					a {
						color: inherit;
						padding: 16px 1.222rem;
						background-color: $color-pink-light;
						display: inline-block;
					}

					&:hover {
						color: white;

						a {
							background-color: $color-pink-gray;
						}
					}
				}
			}

			&:hover {

				.languages-list {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		a {
			&:hover {
				color: $color-pink;
			}
		}

		.extra {
			padding: $small 0;
			width: fit-content;
		}
	}

	.first-level {
		margin-bottom: $large;
		position: relative;
		transition: all .2s linear;

		&.has-children {
			cursor: pointer;
		}

		&.open {

			.arrow {
				transform: rotate(180deg);
			}
		}

		&.inactive {
			opacity: .6;
		}
	}

	.second-level {
		margin-top: $regular;
	}

	.arrow {
		position: absolute;
		right: 0;
		top: 26px;
		cursor: pointer;
		transition: all .2s linear;
		z-index: -1;

		@media (max-width:1440px) {
			top: 17px;
		}
	}

	.second-level-container {
		display: none;
	}

	.center-part {
		height: 100vh;
		max-height: calc(100vh - 180px - 3rem);
		overflow-y: scroll;
		padding-right: 12px;

		//firefox + Edge
		scrollbar-width: 6px;
		scrollbar-color: white transparent; /* Couleur de la poignée et de la piste */

		/* largeur poignée */
		&::-webkit-scrollbar {
			width: 6px;

		}
		
		/* background */
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		
		/* Poignée (thumb) */
		&::-webkit-scrollbar-thumb {
			background: white;
			transition: all .2s linear;
			border-radius: 4px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: $color-pink-light;

			//Firefox + Edge
			scrollbar-color: transparent $color-pink-light;
		}
	}
}