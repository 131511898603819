//
// Template Styles
// --------------------------------------------------
body {
    &.page-template-template-services {

        #page-header.standard-header {

            .container {
                &::after {
                    content: url('../images/arrow-down-right-page-header.svg');
                    left: initial;
                    right: 15%;
                    top: 90%;

                    @media (max-width:991px) {
                        display: none;
                        visibility: hidden;
                    }
                }

                svg#animated-arrow {
                    display: none;
                    visibility: hidden;
                }
            }
        }
    }
    &.page-template-template-partners {

        #page-header.standard-header {
            margin-bottom: 1rem;

            .container {
                &::after {
                    display: none;
                    visibility: hidden;
                }
            }

            svg#animated-arrow {
                display: none;
                visibility: hidden;
            }
        }
    }
    &.page-template-template-homepage {

        #main {
            padding-top: 0;
        }

        h2.intro-title {
            width: 100%;
            max-width: 50%;
            margin: 0 auto;
            margin-bottom: 11.5rem;
            position: relative;

            svg#animated-arrow-left {
                position: absolute;
                top: 106%;
                left: 50%;
                transform: translateX(-50%);
                max-width: 11rem;
            }

            @media (max-width:1920px) {
                max-width: 66%;
            }

            @media (max-width:1441px) {

                svg#animated-arrow-left {
                    width: 150px;
                    transform: translateX(-50%) rotateZ(-25deg);
                }
            }

            @media (max-width:1350px) {
                
                svg#animated-arrow-left {
                    width: 120px;
                }
            }

            @media (max-width:991px) {
                max-width: 83%;
            }

            @media (max-width:767px) {

                svg#animated-arrow-left {
                    width: 100px;
                }
            }
        }

        h1 {
            margin: 0 auto;
            margin-top: 5.5rem;
            text-align: center;
            position: relative;
            margin-bottom: $massive;
            color: $color-pink;
            font-size: 5.5rem !important;
            line-height: 1 !important;

            span {
                font-size: 4.2rem !important;
                color: #E3ABC7;
            }

            @media (max-width:767px) {
                padding: 0 24px;
                font-size: 4.2rem !important;

                span {
                    font-size: 3rem !important;
                }
            }

            @media (max-width:576px) {
                font-size: 3.2rem !important;

                span {
                    font-size: 2.1rem !important;
                }
            }
        }

        .services {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: $medium;
            align-items: center;
            justify-content: center;
            margin-top: -8rem;
            position: relative;

            svg.shape {
                position: absolute;
                left: 45%;
                bottom: -150%;
                z-index: -2;
                transform: translateX(-50%);
                width: calc(100vw + 400px);
                height: auto;

                @media (max-width:1920px) {
                    bottom: -90%;
                }

                @media (max-width:1199px) {
                    bottom: -60%;
                }

                @media (max-width:991px) {
                    display: none;
                    visibility: hidden;
                }
            }

            .service-card {
                background-color: white;
                position: relative;
                display: block;
                border-radius: 4px;
                padding: $big $large;
                padding-bottom: 4.5rem;
                color: $color-text;
                height: 100%;

                div.frame {
                    width: calc(100% - 1rem);
                    height: calc(100% + 2rem);
                }

                &:hover {

                    h2 {
                        color: $color-pink-dark;
                    }

                    .btn-arrow {

                        svg {
                            path:not(.arrow) {
                                fill: $color-pink-dark;
                            }
                        }
                    }
                }

                p {
                    margin-top: .5rem;
                }

                .btn-arrow {
                    position: absolute;
                    right: $large;
                    bottom: .5rem;
                }

                h2 {
                    transition: all .2s linear;
                    color: $color-pink;
                }
            }

            @media (max-width:991px) {
                grid-template-columns: 1fr;
                gap: $huge;
            }
        }
    }
}
#main {
    padding-top: 121px; // Header height
    z-index: 15;
}

#page-header {
    width: 100vw;
    max-width: 2560px;
    margin: 0 auto;

    svg#animated-arrow {
        width: 160px;
        height: auto;
    }

    &.home-header {
        width: 100vw;
        height: 80vh;
        position: relative;
        overflow: hidden;

        video,
        img {
            width: 100vw;
            height: auto;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            object-fit: cover;
        }
    }

    h1 {

        @media (max-width: 991px) {
            font-size: $huge !important;
            line-height: normal;
        }

        &.pink {
            display: inline;
            width: fit-content;
            padding: 0 $small;
            background-color: $color-pink;
            color: white;
            line-height: 1.9;
            box-decoration-break: clone;
            -webkit-box-decoration-break: clone;
        }

        &.pink-title {

        }
    }

    video {
        border-radius: 4px;
        overflow: hidden;
    }

    &.simple-header {

        .illu.pomme {
            right: 10%;
            top: -95%;

            @media (max-width:991px) {
                max-width: 16rem;
                top: 40%;
                right: -15%;
            }
        }

        .illu.pan {
            right: 20%;
            top: -20%;

            @media (max-width:991px) {
                right: -5%;
                top: -40%;
            }

            @media (max-width:576px) {
                max-width: 8rem;
                right: -8%;
                top: 15%;
            }
        }

        .illu.hat {
            right: 10%;
            bottom: -30%;

            @media (max-width:767px) {
                max-width: 8rem;
            }
        }
    }

    &.standard-header {

        .description {

            p {
                font-size: inherit !important;
                line-height: inherit !important;
            }
        }
    }
}

#cta-illus {
    background-color: #F9F8F5;
    padding: 2.8rem 3rem;
    padding-bottom: 4.5rem;
    border-radius: .222rem;

    @media (max-width:991px) {
        padding: 2rem 1.2rem;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 5rem;

        @media (max-width:1199px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 3rem;
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    .illu-bloc {
        display: grid;
        grid-template-rows: 1fr auto;
        height: fit-content;

        .img {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: fit-content;

            img {
                min-height: 190px;
            }

            @media (max-width:576px) {
                max-width: 80%;
                margin-inline: auto;
            }
        }

        b, strong {
            color: #761251;
        }

        .descr {
            text-align: center;
            font-weight: normal;
            margin-top: 1.3rem;
        }
    }
}

.testimonials-slider {
    width: 100%;
    max-width: 83%;
    margin: 0 auto;
    margin-top:9rem;
    margin-bottom:16rem;

    .slick-list,
    .slick-slide {
        overflow: visible !important;
    }

    @media (max-width:991px) {
        max-width: 100%;
    }
}

svg#animated-arrow-left {
    width: 200px;
    height: auto;
}

.illu {
    position: absolute;
    z-index: -1;

    &.rellax {
        z-index: -2 !important;
    }

    &.full {
        opacity: 0;
    }
}

div.illus {
    position: relative;
    z-index: 1;

    .illu {
        transition: all .2s linear;
    }

    &:hover {

        .illu.full {
            opacity: 1;
        }
    }
}

svg.shape {
    z-index: -3 !important;
}

.step {
    width: 100%;
    text-align: center;
    position: relative;

    .step-content {
        width: fit-content;
        margin: 0 auto;
        position: relative;
    }

    .icon {
        background-image: url('../../assets/images/bg-icon-step.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        text-align: center;
        width: fit-content;
        margin: 0 auto;
        margin-bottom: 1rem;
    }

    .description {
        margin: 0 auto;
        margin-top: 1rem;
        max-width: 55%;
    }
}

.author {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .pp {
        width: 40px;
        height: 40px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: .4rem;
        background-color: white;
    }

    .name {
        font-size: 1rem;
        line-height: normal;
        text-transform: uppercase;
        margin: 0;
    }
}

.slider-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media (max-width:767px) {
        display: block;
    }

    .btn-arrow {
        &.next-btn,
        &.prev-btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            background-color:  transparent !important;

            &.slick-disabled {
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        &.next-btn {
            right: $big;
        }

        &.prev-btn {
            left: $big;
        }

        @media (max-width:767px) {

            &.next-btn {
                right: 0;
            }
        }
    }

    &:hover {

        .btn-arrow {
            &.next-btn,
            &.prev-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.offscreen-slider {
    margin-right: -100%;

    .video-bloc {
        width: 100vw;
    }

    .news-bloc,
    .video-bloc {
        margin-right: $medium;
        opacity: .3;
        max-width: calc(25vw - 2.222rem);

        &.slick-active {
            opacity: 1;
        }
    }

    @media (max-width:1440px) {
        .news-bloc,
        .video-bloc {
            max-width: calc(33vw - 2.222rem);
        }
    }

    @media (max-width:991px) {
        .news-bloc,
        .video-bloc {
            max-width: calc(45vw - 2.222rem);
        }
    }

    @media (max-width:576px) {
        margin-right: 0 !important;
        .news-bloc,
        .video-bloc {
            max-width: calc(80vw - 2rem);
            margin-right: 2rem;
            transform: none !important;
        }
    }
}

.video-bloc {

    &.is-playing {

        .video-content {
            &::after {
                display:none !important;
                visibility: hidden !important;
            }
            
            .vid-thumb {
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        .author {
            opacity: 0;
            visibility: hidden;
        }

    }
    
    .video-content {
      display: block;
      width: 100%;
      position: relative;
      padding-bottom: 159%;
      overflow: hidden;

      video {
        position: absolute;
        width: auto;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .vid-thumb {
        position: absolute;
        max-width: fit-content;
        width: auto;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        visibility: visible;
        transition: all .2s linear;
      }
    
      &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: $color-beige;
          opacity: 0;
          transition: all .2s linear;
      }
  }


    .author {
        color: white;
        position: absolute;
        z-index: 2;
        left: $big;
        bottom: $big;
        opacity: 1;
        visibility: visible;
    }

    .btn-play {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        opacity: 1;
        visibility: visible;
        transition: all .2s linear;
        cursor: pointer;

        &.is-hidden {
            opacity: 0;
            visibility: hidden;
        }
    }

    &:hover {

        .video-content {
            
            &::after {
                opacity: .5;
            }
        }
        
        &::after {
            opacity: .8;
        }
    }
}


.timeline-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;

    @media (max-width:767px) {
        display: block;
    }
    
    .timeline-slider {
        margin-right: -100%;

        
        @media (max-width:767px) {
            margin-right: 0;
            
            .slick-list {
                overflow: visible;
            }
        }
    
        .timeline-element {
            max-width: calc(50vw - 2rem);
            margin-right: 2rem;
            overflow: visible !important;

            &.even {
                flex-direction: column-reverse;
                margin-top: 5rem;

                .content {

                    svg.arrow {
                        bottom: initial;
                        top: 0;
                    }
                }
            }

            .content {
                position: relative;

                svg.arrow {
                    position: absolute;
                    bottom: 0;
                    right: 1rem;
                    width: 40%;
                    max-width: 450px;
                }
            }

            &:last-child {
                
                .content {

                    svg.arrow {
                        display: none;
                        visibility: hidden;
                    }
                }
            }

            @media (max-width:1800px) {
                max-width: calc(60vw - 2rem);
            }

            @media (max-width:1441px) {
                max-width: calc(70vw - 2rem);
            }

            @media (max-width:1199px) {
                max-width: calc(80vw - 2rem);
                
                .content {

                    svg.arrow {
                        right: -1rem;
                        max-width: 350px;
                    }
                }
            }

            @media (max-width:991px) {
                max-width: calc(85vw - 2rem);

                .content {

                    svg.arrow {
                        display: none;
                        visibility: hidden;
                    }
                }
            }

            @media (max-width:767px) {
                margin-top: 0 !important;
                max-width: calc(80vw - 2rem);
            }
        }
    }
}


.timeline-element {
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .content {
        display: flex;
        align-items: flex-start;
        text-align: left;
        
        img {
            width: calc(100% - 450px + 3rem);
            max-width: 28rem;
        }

        .description {
            width: 50%;
            max-width: 440px;
            background-color: white;
            padding-top: $large;
            padding-right: $huge;
            padding-left: $huge;
            padding-bottom: $gigantic;
            border-radius: 4px;
            margin-left: -3rem;
            margin-top: 3rem;
            position: relative;

            div.frame {
                width: calc(100% - 1rem);
                height: calc(100% + 2rem);
            }
        }
    }

    .year {
        color: $color-pink;
        font-size: 6.4rem !important;
        line-height: normal;
        font-weight: bold;
        font-family: $font-title;
    }

    @media (max-width:991px) {

        .content {

            img {
                width: calc(100% - 350px + 3rem);
            }

            .description {
                width: 60%;
            }
        }
    }

    @media (max-width:767px) {
        flex-direction: column-reverse;

        .content {
            flex-direction: column;
            
            img {
                width: 100%;
                max-width: 400px;
            }
        }

        .content {

            .description {
                width: calc(100% - 2rem);
                max-width: 400px;
                margin-left: 1rem;
                margin-top: -20%;
                padding: $medium $big;

                svg.frame {
                    display: none;
                }
            }
        }

        .year {
            font-size: 4rem !important;
        }
    }
}

.medias-grid {
    margin-bottom: 8.9rem;
    transform: translateX($huge);

    @media (max-width:767px) {
        transform: translateX(0);
    }

    .grid-sizer,
    .media-content {
        width: calc(100% / 3 - 48px);

        @media (max-width:991px) {
            width: calc(50% - 48px);
        }

        @media (max-width:767px) {
            width: 100%;
        }
    }

    .media-content {
        margin-bottom: 24px;
        float: left;

        .btn-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 1;
            visibility: visible;
            transition: all .2s linear;
            cursor: pointer;

            &.is-hidden {
                opacity: 0;
                visibility: hidden;
            }
        }

        video {
            width: 100%;
            height: auto;
        }

        &.vid {
            overflow: hidden;

            .vid-thumb {
                position: absolute;
                max-width: fit-content;
                width: auto;
                height: 100%;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                visibility: visible;
                transition: all .2s linear;
              }
            &.is-playing {

                .vid-thumb {
                    opacity: 0;
                    visibility: hidden;
                }

            }
        }
    }
}

.popup-member {
    background-color: $color-beige;
    color: $color-text;
    padding: 4.4rem;
    padding-top: 7.7rem;
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 45vw;
    max-width: 785px;
    z-index: 20;
    visibility: hidden;
    opacity: 0;
    transition: all .4s linear;
    overflow-y: scroll;

    		//firefox + Edge
		scrollbar-width: 6px;
		scrollbar-color: white transparent; /* Couleur de la poignée et de la piste */

		/* largeur poignée */
		&::-webkit-scrollbar {
			width: 6px;

		}
		
		/* background */
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		
		/* Poignée (thumb) */
		&::-webkit-scrollbar-thumb {
			background: white;
			transition: all .2s linear;
			border-radius: 4px;
		}
		
		&::-webkit-scrollbar-thumb:hover {
			background: $color-beige-light;

			//Firefox + Edge
			scrollbar-color: transparent $color-beige-light;
		}

    &.is-active {
        right: 0;
        visibility: visible;
        opacity: 1;
    }

    .btn-close {
        position: absolute;
        top: 1.8rem;
        right: 1.8rem;
        background-color: transparent !important;
        padding: 0 !important;

        svg {

            path {
                fill: $color-text;
            }
        }
    }

    .top-part {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.8rem;

        img {
            width: 45%;
            border-radius: 4px;
        }

        h3 {
            margin-top: $gigantic !important;
        }
    }

    .description,
    .quote {
        margin-top: 1.8rem;
    }

    .quote {
        font-family: $font-liniga;
        font-size: 1.35rem;
        line-height: 1.1em;
        padding: 1.4rem 2.5rem;
        position: relative;

        svg {
            position: absolute;
            z-index: -1;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: auto;
        }

        &::before {
            content: "\f10d";
            font-family: $fontawesome-solid;
            font-weight: bold;
            color: white;
            opacity: .3;
            font-size: 1.35rem;
            position: absolute;
            top: 10px;
            left: 10px;
        }
    }

    @media (max-width:1199px) {
        width: 65vw;
    }

    @media (max-width:991px) {
        padding: 3rem;
        padding-top: 6.5rem;

        .top-part {
            flex-direction: column;
            gap: 2rem;

            img {
                width: 60%;
            }
            
            h3 {
                margin-top: 0 !important;
            }
        }
    }

    @media (max-width:767px) {
        width: calc(100vw - 12rem);
        overflow: scroll;

        .btn-close {
            max-width: 30px;
            height: auto;

            svg {
                width: 100%;
                height: auto;
            }
        }
    }

    @media (max-width:576px) {
        width: 100vw;
    }
}

.tabs:not(.page-tabs) {
    display: flex !important;

    .titles {
        width: 41%;
    }

    .descriptions {
        width: 45%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    @media (max-width:991px) {
        flex-direction: column;
        gap: 0;
        
        .titles,
        .descriptions {
            width: 100%;
        }

        .descriptions {
            align-items: flex-start;
        }

        .titles {
            flex-wrap: nowrap;
            overflow-x: scroll;
            gap: 2rem;
        }

        .tab-title {
            margin-bottom: 1.222rem;
        }
    }
}

.accordion {
    position: relative;
    padding: $medium $big;
    border-left: 2px solid black;
    border-right: 2px solid black;

    div.frame {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: none;

        svg {
            position: absolute;
            z-index: 1;

            &.top, &.bottom {
                width: 100%;
                height: auto;
                left: 0;
            }

            &.bottom {
                width: calc(100% - 1.3rem);
            }
        }

        &.top-bottom {
    
            svg.top {
              top: 0;
            }
        
            svg.bottom {
              bottom: 0;
            }
        }
    }

    .title {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        &.is-active {

            svg {

                path {
                    &.vertical {
                        opacity: 0;
                    }
                }
            }
        }

        svg {
            width: 35px;
            min-width: 35px;
            height: auto;
        }
    }

    .accordion-content {
        display: none;
        margin-top: 1rem;
    }
}

.press-block {

    img {
        border-radius: 4px;
    }

    .content {
        border-radius: 4px;
        background-color: white;
        padding: $big;
        padding-bottom: $huge;
        color: $color-primary;
        position: relative;
        display: block;
        width: calc(100% - 1.5rem);
        margin: 0 auto;
        margin-top: -2.7rem;

        .btn-download {
            position: absolute;
            right: $big;
            bottom: .5rem;
            padding-bottom: 2px;
            text-transform: uppercase;
        }

        div.frame {
            position: absolute;
            z-index: 1;
            width: calc(100% - 1rem);
            height: calc(100% + 1rem);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          
            svg {
              position: absolute;
              z-index: 1;
          
              &.top, &.bottom {
                width: 100%;
                height: auto;
                left: 0;
              }

              &.bottom {
                width: calc(100% - .5rem);
              }
          
              &.left, &.right {
                width: auto;
                bottom: 0;
              }
            }
          
            &.top-bottom {
              
              svg.top {
                top: 0;
              }
          
              svg.bottom {
                bottom: 0;
              }
            }
          
            &.left-right {
          
              svg.right {
                height: 100%;
                right: 0;
              }
          
              svg.left {
                height: calc(100% - .5rem);
                left: 0;
              }
            }
          }
    }
}

.job-bloc {
    background-color: white;
    border-radius: 4px;
    position: relative;
    padding: $medium 1rem $large $medium;
    display: block;

    .btn-arrow {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
    }

    h3 {
        margin-bottom: $medium;
    }

    .infos {
        padding: 0;
        margin: 0;
        list-style: none;
        color: $color-pink-gray;
        font-size: $small;
        text-transform: uppercase;

        li {
            margin-bottom: .3rem;
        }
    }

    div.frame {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        transform: translate(0, 0);
      
        svg {
          position: absolute;
          z-index: 1;
      
          &.top, &.bottom {
            width: 100%;
            height: auto;
            left: 0;
          }

          &.bottom {
            width: calc(100% - .5rem);
          }
      
          &.left, &.right {
            width: auto;
            bottom: 0;
          }
        }
      
        &.top-bottom {
          
          svg.top {
            top: 0;
          }
      
          svg.bottom {
            bottom: 0;
          }
        }
      
        &.left-right {
      
          svg.right {
            height: 100%;
            right: 0;
          }
      
          svg.left {
            height: calc(100% - .5rem);
            left: 0;
          }
        }
    }
}

#page-career {

    #cta-illus {
        margin-top: 15rem;

        @media (max-width:991px) {
            margin-top: 0;
        }
    }

    .page-tabs {

        section {
            max-width: 66%;
            margin: 0 auto;
            margin-bottom: 8rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $large;
            position: relative;

            &:first-child {

                .illu {
                    left: initial;
                    right: -12%;
                    top: -4%;
                }
            }

            &:nth-child(2) {
                
                .illu {
                    left: -25%;
                    top: 8%;
                }
            }

            &:nth-child(3) {

                .illu {
                    left: initial;
                    right: -30%;
                    top: -3%;
                }
            }

            @media (max-width:1199px) {
                max-width: 83%;
            }

            @media (max-width:767px) {
                max-width: 100%;
                grid-template-columns: 1fr;

                div.full {
                    grid-column: span 1 !important;
                }
            }

            div.full {
                grid-column: span 2;
            }

            h3.pink {
                text-align: center;
                margin: 0 auto;
                margin-bottom: 1.3rem;
            }

            &:last-child {
                margin-bottom: 0;
            }

            h4.title {
                margin-bottom: $big;
                text-align: center;
            }

            .word-from {
                .media {
                    margin-bottom: 1.333rem;
                    position: relative;

                    .author {
                        color: white;
                        position: absolute;
                        left: 1rem;
                        bottom: 1.2rem;
                        z-index: 2;
                    }

                    .word {
                        color: $color-pink;
                        margin-bottom: 1rem;
                        display: inline-block;
                        position: relative;

                        &::before {
                            content: url('../images/arrow-media-word-from.svg');
                            position: absolute;
                            right: calc(100% + 8px);
                            top: 12px;
                        }
                    }

                    .img,
                    .vid {
                        overflow: hidden;
                        border-radius: 4px;
                        position: relative;
                        display: flex;

                        &::after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 35.06%);
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .icon-section {

                img {
                    width: auto;
                }

                h5 {
                    color: $color-primary;
                    margin-bottom: .5rem;
                    max-width: 80%;
                }

                .description {

                    ul {
                        padding: 0;
                        list-style-position: inside;
                        margin: 0;
                        margin-top: .5rem;
                        margin-bottom: 2rem;
                    }
                }
            }

            .btn.arrow.mail {
                margin: 0 auto;
                margin-bottom: $huge;
            }

            .quote {
                font-family: $font-liniga;
                font-size: $large;
                line-height: 1.1em;
                padding: 2.8rem 0 0 2.5rem;
                position: relative;
                max-width: 100%;
                margin-bottom: $gigantic;
                text-align: center;

                svg {
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    top: -1rem;
                    width: 100%;
                    height: auto;
                }
            }

            .num {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                .descr {
                    margin-top: $huge;

                    &.first {
                        order: 1;
                        margin-bottom: $huge;
                        margin-top: 0;
                    }
                }

                .number-bloc {
                    order: 2;
                    margin: 0 auto;

                    @media (max-width:1441px) {
                        width: 100%;
                    }
                }
            }

            .txt-img {
                margin-top: $massive;

                &:first-of-type {
                    margin-top: 0;
                }

                .img {
                    position: relative;
                    border-radius: 4px;
                    
                    // &::after {
                    //     content: "";
                    //     position: absolute;
                    //     left: 0;
                    //     bottom: 0;
                    //     width: 100%;
                    //     height: 100%;
                    //     z-index: 1;
                    //     background: linear-gradient(360deg, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0.0975) 44.22%, rgba(255, 255, 255, 0) 100%);
                    // }

                    img.hover {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                        opacity: 0;
                        transition: all .2s linear;
                        width: 100%;
                        height: auto;
                    }
        
                    &:hover {
                        
                        img.hover {
                            opacity: 1;
                        }
                    }
                }

                .txt {
                    width: calc(100% - 1.9rem);
                    margin: 0 auto;
                    margin-top: -2.2rem;
                    position: relative;
                    z-index: 1;

                    h4, h3 {
                        color: $color-primary;
                        margin-bottom: .5rem;
                    }

                    p {
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }

                &.no-img {

                    .txt {
                        width: 100%;
                    }
                }
            }

            .quote-paragraph {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 4rem;
                
                &.invert {
                    flex-direction: column-reverse;
                    justify-content: flex-end;

                    .quote {
                        margin-bottom: 0;
                    }

                    .paragraph {
                        margin-bottom: 2rem;
                        margin-top: 1rem;

                        @media (min-width:1450px) {
                            margin-top: 3rem;
                        }
                    }
                }

                .quote {
                    width: 100%;
                }
            }
        }
    }
}

#page-job-single {
    margin-bottom: 6rem;

    #post-content {
        max-width: 66%;
        margin: 0 auto;
        margin-bottom: 6rem;
        position: relative;

        .illu {
            left: -40%;
            top: 40%;

            @media (max-width:1440px) {
                display: none;
            }
        }

        @media (max-width:991px) {
            max-width: 83%;
        }

        @media (max-width:767px) {
            max-width: 100%;
        }

        h2 {
            font-size: $large !important; //34px
            line-height: normal;
            font-family: $font-title;
            font-weight: 600;
            margin-bottom: 1.3rem;
          
            @media (max-width:767px) {
              font-size: 1.5rem !important;
              line-height: normal;
            }
        }

        h3 {
            font-size: $big !important; //22px
            line-height: normal;
            font-family: $font-base;
            font-weight: 600;
            color: $color-primary;
        }

        h4 {
            font-size: $medium !important; //20px
            line-height: normal;
            font-family: $font-base;
            font-weight: 400;
          
            @media (max-width: 767px) {
              font-size: $regular !important;
              line-height: normal;
            }
        }

        ul {
            padding: 0;
            list-style-position: inside;
            margin: 0;
            margin-top: .5rem;
            margin-bottom: 2rem;
        }

        p {
            margin: 0;
            margin-bottom: 2rem;
        }
    }

    .btn-primary {
        margin-left: auto;
        margin-top: 1.3rem;

        @media (max-width:576px) {
            width: 100%;
        }
    }

    .related-jobs {
        position: relative;

        .illu {
            right: -2%;
            top: -6%;

            @media (max-width:991px) {
                right: -10%;
                top: 10%;
                max-width: 16rem;
            }
        }

        h2 {
            text-align: center;
            margin-bottom: 2rem;
            line-height: 2.2em;

            strong {
                margin: 0 auto;
                margin-bottom: 0.5rem;
            }
        }
    }
}

.jobs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.3rem;

    @media (max-width:1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:576px) {
        grid-template-columns: 1fr;
    }
}

#page-press {
    margin-bottom: 8.9rem;

    .press {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 1.3rem;
        row-gap: $massive;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            grid-template-columns: 1fr;
        }
    }
}

#page-faq {
    margin-bottom: 8.9rem;

    .faq {
        max-width: 66%;
        margin: 0 auto;

        @media (max-width:1199px) {
            max-width: 83%;
        }

        @media (max-width:991px) {
            max-width: 100%;
        }
    }

    .accordion {
        width: 100%;
        max-width: 810px;
        margin: 0 auto;
        margin-bottom: $big;
    }
}

#page-contact {
    margin-bottom: 8.9rem;

    .container {
        position: relative;

        svg.shape {
            position: absolute;
            z-index: -1;
            right: 97%;
            top: -6%;
        }
    }

    .page-content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $big;

        @media (max-width:991px) {
            grid-template-columns: 1fr;
            gap: 4.4rem;
        }
    }

    .infos {

        .address {
            margin-bottom: $big;
        }
    }

    .contact {

        a:not(:last-child) {
            margin-bottom: 1.6rem;
        }

        .icon-container {
            width: 51px;
            height: auto;

            svg {
                margin-left: auto;
            }
        }
    }

    .other {
        margin-top: 4.4rem;

        div {
            margin-bottom: 4rem;
            position: relative;

            svg.shape {
                position: absolute;
                z-index: -1;
                max-width: 7rem;
                top: 50%;
                left: -2rem;
                transform: translateY(-50%);

                @media (max-width:576px) {
                    left: -1rem;
                }
            }
        }

        p {
            margin-bottom: .5rem;
            font-weight: bold;
        }

        .mail {
            padding-bottom: 2px;
            border-bottom: 1px solid $color-pink;

            &:hover {
                border-color: $color-link-hover;
            }
        }
    }

    #form-content {
        position: relative;

        svg.shape {
            position: absolute;
            z-index: -1;
            bottom: 15%;
            right: -15%;
            top: initial;
        }

        h2 {
            margin-bottom: $massive;
        }

        .gform_footer {

            input {
                color: white;
                background-color: $color-pink;
                padding: .5rem $big;
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;
                border-radius: 50px;
                font-size: $big;
                line-height: normal;
                font-weight: 600;
              
                &:hover {
                  background-color: $color-pink-dark;
                  color: white;
                }

                @media (max-width:767px) {
                    width: 100%;
                }
            }
        }
    }
}

#page-service-single {
    margin-top: 8rem;
    margin-bottom: 6rem;
    position: relative;

    .illu {
        &:first-child {
            left: 3%;
            top: 4%;
        }

        &.cta-illu {
            left: initial;
            right:-2%;
            top: -15%;
            max-width: 16rem;

            @media (max-width:767px) {
                max-width: 10rem;
                top: -24%;
            }
        }
    }

    @media (max-width:991px) {
        margin-top: 5rem;
        margin-bottom: 6rem;
    }

    .restaurants-slider-section {
        position: relative;

        .illu {
            left: initial;
            right: -2%;
            top: -25%;

            @media (max-width:991px) {
                max-width: 9rem;
                top: -5%;
            }
        }

        .slider-container {

            .buttons {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1rem;
                margin: 0 auto;
                position: absolute;
                z-index: 1;
                left: 0;
                width: 95%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                .btn-arrow {
                    width: 25px;

                    &.prev-btn,
                    &.next-btn {
                        position: static;
                    }
                }

                @media (max-width:991px) {
                    top: 18rem;
                    transform: translateX(-50%);
                }
            }
        }

        .restaurants-slider {

            .restaurant-bloc {
                opacity: .3;
                margin-right: 5rem;

                &.slick-active {
                    opacity: 1;
                }

                h2 {
                    color: $color-pink;
                }

                p.description {
                    max-width: 50rem;
                }

                .btn-external {
                    margin-top: .5rem;
                    margin-left: auto;
                }

                .content {
                    margin-top: $massive;
                    display: flex;
                    align-items: flex-start;

                    img {
                        width: calc(100% - 470px + 3rem);
                        max-width: 28rem;
                    }

                    .bloc-content {
                        list-style: none;
                        margin: 0;
                        padding: $big $massive;
                        border-radius: 4px;
                        width: 45%;
                        max-width: 26rem;
                        margin-left: -3rem;
                        margin-top: 3rem;
                        position: relative;
                        background-color: white;

                        h3 {
                            color: $color-primary;
                            margin-bottom: 0;
                            margin-left: .5rem;
                        }

                        p {
                            margin-top: .5rem;
                            width: 100%;
                        }

                        div.frame {
                            width: calc(100% - 1rem);
                            height: calc(100% + 2rem);
                        }

                        li {
                            margin-bottom: $big;
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                        }
                    }
                }

                @media (max-width:1199px) {
                    max-width: 85vw;

                    .content {

                        .bloc-content {
                            width: 55%;
                            margin-left: -5rem;
                        }
                    }
                }

                @media (max-width:991px) {

                    .content {
                        flex-direction: column;
                        align-items: center;

                        img {
                            width: auto;
                        }

                        .accordion {
                            width: 100%;
                            margin-top: 1rem;
                        }

                        .bloc-content {
                            width: 100%;
                            margin: 0 auto;
                            margin-top: 1.5rem;
                            padding: 0;

                            ul {
                                padding: 0;
                                margin: 0;
                                list-style: none;
                                margin-top: 1.5rem;
                            }
                        }
                    }
                }

                @media (max-width:767px) {

                    .content {

                        img {
                            width: 100%;
                            max-width: fit-content;
                        }
                    }
                }
            }

            @media (max-width:991px) {

                .slick-list {
                    height: auto !important;
                }
            }
        }
    }

    .opening-section {

        h2 {
            text-align: center;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            strong {
                text-align: center;
                margin: 0 auto;
                margin-bottom: .5rem;
            }
        }

        .blocs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $big;

            @media (max-width:991px) {
                grid-template-columns: 1fr;
                gap: $large;
            }
        }

        .service-bloc {

            img {
                border-radius: 4px;
            }

            h3 {
                text-align: center;
                padding: $large;
                background-color: white;
                border-radius: 4px;
                width: calc(100% - 4rem);
                margin: 0 auto;
                margin-top: -3rem;
                position: relative;

                div.frame {
                    width: calc(100% - 2rem);
                    height: calc(100% + 2rem);
                }
            }
        }
    }

    section {

        &.text {
            max-width: 66%;
            margin: 0 auto;
            margin-bottom: 4.5rem;
            text-align: center;

            h2 {
                margin: 0 auto;
                margin-bottom: $large;
            }

            @media (max-width:1441px) {
                max-width: 83%;
            }

            @media (max-width:991px) {
                max-width: 100%;
            }

            h3 {
                font-size: 1.5rem !important;
                line-height: normal !important;
            }
        }

        &.cta {
            margin-top: 3.5rem;
        }

        &.word-from {
            margin-bottom: 5rem;

            .setting {
                max-width: 75%;
                margin: 0 auto;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 2rem;

                @media (max-width:1441px) {
                    max-width: 83% !important;
                }

                @media (max-width:1199px) {
                    max-width: 100% !important;
                }

                @media (max-width:991px) {
                    grid-template-columns: 1fr !important;
                    gap: 1rem;

                    .right {
                        margin-top: 0 !important;
                    }
                }

                &.setting-1 {
                    grid-template-columns: 45% 55%;

                    .quote {
                        margin-top: 3rem;
                    }

                    .right {
                        text-align: center;
                    }
                }

                &.setting-2 {
                    position: relative;

                    .illu {
                        left: initial;
                        top: initial;
                        right: -12%;
                        bottom: -15%;

                        @media (max-width:991px) {
                            bottom: -30%;
                            max-width: 17rem;
                        }
                    }
                }

                &.setting-3 {

                    .quote {
                        max-width: 85%;

                        &::after {
                            content: url('../images/arrow-quote.svg');
                            position: absolute;
                            z-index: -1;
                            top: calc(100% + 3rem);
                            left: 50%;
                            transform: translateX(-50%);
                        }

                        @media (max-width:991px) {
                            margin-top: 2rem;
                            
                            &::after {
                                display: none;
                                visibility: hidden; 
                            }
                        }
                    }
                }

                &.setting-4 {
                    position: relative;
                    
                    .illu {
                        right: -22%;
                        top: -10%;
                        left: initial;
                    }

                    .right {
                        margin-top: 0;
                    }

                    .left {
                        margin-top: 3.9rem;
                    }

                    .number-bloc {
                        margin-top: 0 !important;

                        &::after {
                            display: none !important;
                            visibility: hidden !important;
                        }
                    }
                }

                &.setting-5 {
                    margin-top: 2rem;
                    grid-template-columns: 45% 55%;

                    .number-bloc {
                        margin-top: 16rem;

                        @media (max-width:767px) {
                            margin-top: 11rem !important;
                        }
                    }
                }

                .media {
                    margin-bottom: 1.333rem;
                    position: relative;

                    .author {
                        color: white;
                        position: absolute;
                        left: 1rem;
                        bottom: 1.2rem;
                        z-index: 2;
                    }

                    .word {
                        color: $color-pink;
                        margin-bottom: 1rem;
                        display: inline-block;
                        position: relative;

                        &::before {
                            content: url('../images/arrow-media-word-from.svg');
                            position: absolute;
                            right: calc(100% + 8px);
                            top: 12px;
                        }
                    }

                    .img,
                    .vid {
                        overflow: hidden;
                        border-radius: 4px;
                        position: relative;
                        display: flex;

                        &::after {
                            content: "";
                            position: absolute;
                            z-index: 1;
                            width: 100%;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 35.06%);
                        }

                        img {
                            width: 100%;
                        }
                    }
                }

                .description {

                    h2 {
                        font-size: $large !important; //34px
                        line-height: normal;
                        font-family: $font-title;
                        font-weight: 600;
                      
                        @media (max-width:767px) {
                          font-size: 1.5rem !important;
                          line-height: normal;
                        }
                    }
            
                    h3 {
                        font-size: $big !important; //22px
                        line-height: normal;
                        font-family: $font-base;
                        font-weight: 600;
                    }
            
                    h4 {
                        font-size: $medium !important; //20px
                        line-height: normal;
                        font-family: $font-base;
                        font-weight: 400;
                      
                        @media (max-width: 767px) {
                          font-size: $regular !important;
                          line-height: normal;
                        }
                    }
                }

                .quote {
                    font-family: $font-liniga;
                    font-size: $large;
                    line-height: 1.1em;
                    margin: 2.8rem 0 0 2.5rem;
                    position: relative;
                    max-width: 75%;

                    @media (max-width:1441px) {
                        max-width: 85%;
                    }

                    @media (max-width:1199px) {
                        max-width: 90%;
                    }

                    @media (max-width:991px) {
                        max-width: 80%;
                        margin: 0 auto;
                    }

                    @media (max-width:767px) {
                        max-width: 90% !important;
                    }

                    svg {
                        position: absolute;
                        z-index: -1;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: auto;
                    }
                }

                .number-bloc {
                    margin-top: 20rem;

                    &::after {
                        content: url('../images/arrow-number.svg');
                        position: absolute;
                        z-index: -1;
                        bottom: 100%;
                        left: 50%;
                        transform: translateX(-50%);

                        @media (max-width:991px) {
                            content: url('../images/arrow-number-mobile.svg');
                        }
                    }

                    @media (max-width:1441px) {
                        margin-top: 15rem !important;
                    }

                    @media (max-width:991px) {
                        margin-top: 11rem !important;
                    }

                    @media (max-width:767px) {
                        margin-top: 14rem !important;
                    }
                }

                .right {
                    margin-top: 2rem;
                }
            }
        }
    }

    .services {

        h2 {
            margin: 0 auto;
            text-align: center;
            margin-bottom: $gigantic;
        }

        .services-slider {
            margin-right: -100%;

            .service-supplementary {
                margin-right: 1.4rem;
                width: calc(25vw - 4.8rem);
                opacity: .3;
                transition: all .2s linear;

                &.slick-active {
                    opacity: 1;
                }

                @media (max-width:1441px) {
                    width: calc(33vw - 4.8rem);
                }

                @media (max-width:991px) {
                    width: calc(50vw - 4.8rem);
                }

                @media (max-width:576px) {
                    width: calc(100vw - 50px);
                }
            }
        }
    }

    .service-supplementary {
        display: block;
        color: $color-text;

        .img {
            margin-bottom: $big;
        }

        h3 {
            color: $color-primary;
        }

        p {
            margin-top: 0;
        }
    }

    .references {
        margin-top: 6rem;
        position: relative;

        .illu {
            left: 1%;
            top: -25%;

            @media (max-width:991px) {
                top: -30%;
                max-width: 15rem;
            }
        }

        h2 {
            text-align: center;
            margin: 0 auto;
            margin-bottom: $massive;
        }

        h3 {
            margin: 0 auto;
            margin-bottom: 5rem;
            max-width: 66%;
            text-align: center;

            @media (max-width:1199px) {
                max-width: 83%;
            }

            @media (max-width:991px) {
                max-width: 100%;
            }
        }

        .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: $gigantic;
            row-gap: 5.5rem;
            margin: 0 auto;
            max-width: 55%;

            @media (max-width:1441px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media (max-width:991px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width:576px) {
                max-width: 90%;
            }
        }
    }
}

#page-blog-single {

    #post-content {
        max-width: 66%;
        margin: 0 auto;
        margin-bottom: 6rem;
        position: relative;

        .illu {
            &:first-child {
                left: -20%;
                top: 30%;
            }

            &:nth-child(2) {
                right: -25%;
                top: 13%;
            }

            @media (max-width:767px) {
                display: none;
                visibility: hidden;
            }
        }

        @media (max-width:991px) {
            max-width: 83%;
        }

        @media (max-width:767px) {
            max-width: 100%;
        }

        h2 {
            font-size: $large !important; //34px
            line-height: normal;
            font-family: $font-title;
            font-weight: 600;
          
            @media (max-width:767px) {
              font-size: 1.5rem !important;
              line-height: normal;
            }
        }

        h3 {
            font-size: $big !important; //22px
            line-height: normal;
            font-family: $font-base;
            font-weight: 600;
        }

        h4 {
            font-size: $medium !important; //20px
            line-height: normal;
            font-family: $font-base;
            font-weight: 400;
          
            @media (max-width: 767px) {
              font-size: $regular !important;
              line-height: normal;
            }
        }
    }

    .related-news {
        position: relative;
        margin-bottom: 6rem;

        svg.shape {
            position: absolute;
            z-index: -1;
            top: 15%;
            left: -2%;
            max-width: 27rem;
            height: auto;
        }

        .illu {
            position: absolute;
            z-index: -1;
            top: -10%;
            left: -10%;
        }

        h2 {
            margin: 0 auto;
            margin-bottom: 2rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            strong {
                margin: 0 auto;
                margin-bottom: 0.5rem;
            }
        }

        @media (max-width:991px) {

            .illu {
                width: 35vw;
                top: initial;
                bottom: 75%;
            }

            svg.shape {
                display: none;
                visibility: hidden;
            }
        }

        @media (max-width:767px) {

            .illu {
                bottom: 85%;
            }
        }
    }
}

.page-tabs {
    margin-top: 8rem;
    position: relative;

        
    .illu {
        &:first-child {
            left: -30%;
            top: 5%;
        }

        @media (max-width:767px) {
            display: none;
            visibility: hidden;
        }
    }

    
    .illu {
        &:nth-child(2) {
            right: -2%;
            top: 35%;
        }
    }

    .titles {
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        border: 1px solid $color-pink;
        border-radius: 4px;
        color: $color-pink;
        overflow: hidden;
        margin: 0 auto;

        .tab-title {
            cursor: pointer;
            padding: .6rem 4.2rem;
            text-align: center;
            background-color: white;
            border-right: 1px solid $color-pink;
            transition: all .2s linear;
            min-width: 245px;

            &:last-child {
                border-right: none;
            }

            &:hover {
                color: white;
                background-color: $color-pink-dark;
            }

            &.active {
                color: white;
                background-color: $color-pink;
            }
        }

        @media (max-width:991px) {
            border: none;
            gap: 1.5rem;
            flex-wrap: nowrap;
            overflow-x: scroll;
            width: 100%;
            justify-content: flex-start;
            padding-bottom: 1rem;

            .tab-title {
                min-width: auto;
                border: 1px solid $color-pink-light;
                background-color: $color-pink-light;
                color: $color-primary;
                padding: .3rem 1rem;
                border-radius: 4px;

                &.active {
                    border-color: $color-primary;
                }

                &:hover {
                    border-color: $color-pink-dark;
                }
            }
        }
    }

    .tabs-content {
        position: relative;
        margin-top: $gigantic;
        margin-bottom: 6rem;

        .tab {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            display: none;

            &.active {
                position: static;
                display: block;
            }

            section {
                max-width: 66%;
                margin: 0 auto;
                margin-bottom: $gigantic;

                &.text {

                    h2 {
                        font-size: $large !important; //34px
                        line-height: normal;
                        font-family: $font-title;
                        font-weight: 600;
                      
                        @media (max-width:767px) {
                          font-size: 1.5rem !important;
                          line-height: normal;
                        }
                    }

                    h3 {
                        font-size: $big !important; //22px
                        line-height: normal;
                        font-family: $font-base;
                        font-weight: 600;
                    }

                    h4 {
                        font-size: $medium !important; //20px
                        line-height: normal;
                        font-family: $font-base;
                        font-weight: 400;
                      
                        @media (max-width: 767px) {
                          font-size: $regular !important;
                          line-height: normal;
                        }
                    }
                }

                &.numbers {

                    .content {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-content: center;
                        gap: 7.2rem;
                    }
                }

                &.files {

                    .content {
                        margin-top: 1rem;
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        gap: 1.5rem;
                    }
                }

                &.logos {

                    .content {
                        margin-top: 1rem;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        gap: $gigantic;
                    }

                    .logo {
                        height: 5.5rem;
                        width: auto;

                        img {
                            height: 100%;
                            width: auto;
                        }
                    }
                }

                &.pictures {
                    margin-top: 8rem;
                    max-width: 100%;
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1.333rem;

                    .quote {
                        margin: 0 auto;
                        margin-top: 1.333rem;
                        font-family: $font-liniga;
                        font-size: $large;
                        line-height: 1.1em;
                        position: relative;
                        padding: 4.4rem 4rem;
                        width: fit-content;
                        max-width: 80%;

                        svg {
                            position: absolute;
                            z-index: -1;
                            width: 100%;
                            height: auto;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .right {

                        .img {
                            margin-top: 4.4rem;

                            svg {
                                left: 50%;
                                bottom: -12%;
                                transform: translateX(-50%);
                                height: auto;
                                width: 80%;
                            }
                        }
                    }

                    .img {
                        position: relative;
                        
                        svg {
                            position: absolute;
                            z-index: -1;
                        }
                    }

                    .left {

                        .img {

                            svg {
                                left: -10%;
                                top: -10%;
                                width: 75%;
                                height: auto;
                            }
                        }
                    }
                }

                @media (max-width:1441px) {
                    max-width: 83%;

                    &.numbers {

                        .content {
                            gap: 4rem;
                        }
                    }

                    &.pictures {

                        .quote {
                            max-width: 90%;
                        }
                    }
                }

                @media (max-width:1199px) {

                    &.pictures {

                        .quote {
                            max-width: 100%;
                        }
                    }
                }

                @media (max-width:991px) {
                    max-width: 100%;
                    margin-bottom: 1.8rem;

                    &.logos {

                        .logo {
                            height: 4.5rem;
                        }
                    }

                    &.pictures {
                        grid-template-columns: 1fr;
                        margin-top: 0;

                        .img {

                            svg {
                                display: none;
                                visibility: hidden;
                            }
                        }

                        .quote {
                            max-width: 70%;
                            margin-top: $massive;
                            order: 2;
                        }

                        .right {
                            display: flex;
                            flex-direction: column;

                            .img {
                                order: 1;
                                margin-top: 0;
                            }
                        }
                    }

                    &.numbers {

                        .content {
                            flex-direction: column;
                            align-items: center;
                            justify-content: flex-start;
                            gap: $big;
                        }
                    }
                }

                @media (max-width:767px) {

                    &.pictures {

                        .quote {
                            max-width: 90%;
                            font-size: 1.25rem;
                        }
                    }
                }

                @media (max-width:576px) {

                    &.pictures {

                        .quote {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#page-blog,
#page-blog-category {

    .filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: $huge;
        font-size: 1rem;
        text-transform: uppercase;
        color: $color-text;

        li {
            &.active {
                color: $color-pink;
                border-bottom: 1px solid $color-pink;

                a {
                    cursor: initial;
                }
            }

            a {
                color: inherit;
            }

            &:hover {
                color: $color-pink-dark;
            }
        }

        @media (max-width:767px) {
            row-gap: 1rem;
            column-gap: 2rem;
        }
    }

    .news {
        margin-top: 2.222rem;
        margin-bottom: 6rem;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1.333rem;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            grid-template-columns: 1fr;
        }

        .news-bloc {
            width: 100%;
        }
    }
}

#page-services {
    margin-bottom: 5rem;

    .services {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $big;
        row-gap: 4rem;
        width: 100%;
        max-width: 80%;
        margin: 0 auto;

        @media (max-width:1199px) {
            max-width: 85%;
        }

        @media (max-width:991px) {
            max-width: 100%;
        }

        @media (max-width:1199px) {
            grid-template-columns: 1fr;

            .service-bloc {
                max-width: 680px;
                margin: 0 auto;
            }
        }

        .service-bloc {
            display: block;
            position: relative;
            text-align: center;

            h2 {
                text-align: center;
                margin-bottom: -2.8rem;
                position: relative;
                z-index: 1;
                transition: all .2s linear;
                width: 100%;
                max-width: calc(100% - 2rem);
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -2.8rem;
            }

            .img {
                position: relative;
                border-radius: 4px;
                overflow: hidden;
                display: flex;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    background-color: $color-beige;
                    transition: all .2s linear;
                }
            }

            .btn-arrow {
                position: absolute;
                z-index: 1;
                bottom: 1rem;
                right: 1rem;
            }

            &:hover {

                .img {
                    &::after {
                        opacity: .5;
                    }
                }
            }
        }
    }

    .services-supp {
        margin-top: 6rem;
        text-align: center;

        h2 {
            margin: 0 auto;
            text-align: center;
            margin-bottom: $gigantic;
        }

        .services-slider {
            margin-right: -100%;

            .service-supplementary {
                margin-right: 1.4rem;
                width: calc(25vw - 4.8rem);
                opacity: .3;
                transition: all .2s linear;

                &.slick-active {
                    opacity: 1;
                }

                @media (max-width:1441px) {
                    width: calc(33vw - 4.8rem);
                }

                @media (max-width:991px) {
                    width: calc(50vw - 4.8rem);
                }

                @media (max-width:576px) {
                    width: calc(100vw - 50px);
                }
            }
        }
    }

    .service-supplementary {
        display: block;
        color: $color-text;

        .img {
            margin-bottom: $big;
        }

        h3 {
            color: $color-primary;
        }

        p {
            margin-top: 0;
        }

        h3, p {
            text-align: left;
        }
    }
}

#page-team {

    h2 {
        margin: 0 auto;
        margin-bottom: $gigantic;
        text-align: center;

        svg {
            display: none;
            visibility: hidden;
            width: 25px;
            min-width: 25px;
            height: auto;
        }

        @media (max-width:991px) {

            strong {
                background-color: transparent;
                padding: 0;
                color: $color-pink;
                text-align: left;
            }
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 0;
            gap: 1rem;
            cursor: pointer;
            
            svg {
                display: initial;
                visibility: visible;
            }

            &.is-active {

                svg {

                    path {
                        &.vertical {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .team-section {
        margin-bottom: 7rem;
        position: relative;

        @media (max-width:991px) {
            margin-bottom: $large;
            border-bottom: 1px solid $color-pink-light;
            padding-bottom: $big;
        }

        .illu {
            position: absolute;
            z-index: -1;

            @media (max-width:991px) {
                display: none;
                visibility: hidden;
            }
        }

        &:first-child {

            .illu {
                top: 5%;
                left: -5%;

                @media (max-width:1199px) {
                    left: -15%;
                }
            }
        }

        &:nth-child(2) {

            .illu {
                right: -5%;
                top: -20%;

                @media (max-width:1441px) {
                    top: -5%;
                }
            }
        }

        &:nth-child(3) {

            .illu {
                left: -5%;
                top: -10%;
            }
        }
    }

    .direction-img {
        width: 100%;
        max-width: 83%;
        margin: 0 auto;

        @media (max-width:991px) {
            max-width: 100%;
        }
    }

    ul.list {
        width: 100%;
        max-width: calc(83% - 2rem);
        padding: $large $huge;
        margin: 0 auto;
        margin-top: -4rem;
        background-color: white;
        border-radius: 4px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: .5rem;
        row-gap: 1rem;
        position: relative;
        list-style: none;

        div.frame {
            position: absolute;
            z-index: 1;
            width: calc(100% - 2rem);
            height: calc(100% + 2rem);
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            
            @media (max-width:1199px) {
                width: calc(100% - 2rem);
                height: calc(100% + 2rem);
            }
            
            svg {
                position: absolute;
                z-index: 1;
            
                &.top, &.bottom {
                width: 100%;
                height: auto;
                left: 0;
                }
            
                &.left, &.right {
                width: auto;
                bottom: 1rem;
                }
            }
            
            &.top-bottom {
                
                svg.top {
                top: 0;
                }
            
                svg.bottom {
                bottom: 0;
                }
            }
            
            &.left-right {
            
                svg.right {
                height: calc(100% - 2rem);
                right: 0;
                }
            
                svg.left {
                height: calc(100% - 3rem);
                left: 0;
                }
            }
        }

        @media (max-width:991px) {
            max-width: calc(100% - 2rem);
        }

        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;

            div.frame {
                display: none;
                visibility: hidden;
            }

            li {
                &::after {
                    display: none !important;
                    visibility: hidden !important;
                }
            }
        }

        li {
            color: $color-pink;
            font-weight: bold;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &::after {
                content: "";
                width: .5rem;
                height: .5rem;
                border-radius: 50%;
                background-color: #9C7186;
                margin-left: .5rem;
            }

            &:last-child {
                &::after {
                    display: none;
                    visibility: hidden;
                }
            }

            .job {
                font-weight: 400;
                color: $color-text;
            }
        }
    }

    .accordion-content {

        @media (max-width:991px) {
            display: none;
            margin-top: $big;
            margin-bottom: 2rem;

            &.is-open {
                display: block;
            }
        }
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: $medium;
        row-gap: $gigantic;
        position: relative;

        .member-bloc {
            &:nth-child(odd) {
                margin-top: $gigantic;
            }
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);
            display: none;

            &.is-open {
                display: grid !important;
            }

            .member-bloc {
                margin-top: 0 !important;
            }
        }
    }

    .member-bloc {
        color: $color-text;

        img {
            width: 100%;
        }

        .img-container {
            position: relative;
            overflow: hidden;

            img.hover {
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                opacity: 0;
                transition: all .2s linear;
            }

            &:hover {
                
                img.hover {
                    opacity: 1;
                }
            }
        }

        .content {
            z-index: 1;
            padding: .5rem $medium;
            background-color: white;
            border-radius: 4px;
            margin: 0 auto;
            margin-top: -2.1rem;
            position: relative;
            max-width: calc(100% - 1rem);

            div.frame {
                width: calc(100% - 1rem);
                height: calc(100% + 2rem);
            }

            h3 {
                color: $color-pink;
                margin-bottom: .5rem;
            }

            .job,
            .location {
                display: block;
                font-size: $small;
            }
        }
    }
}

#single-partner {

    .steps {
        display: flex;
        flex-wrap: wrap;
        gap: $medium;
        justify-content: center;
        max-width: 1570px;
        margin: 0 auto;
        margin-bottom: 8.9rem;

        .step {
            max-width: 35%;

            .step-content {

                &::after,
                &::before {
                    position: absolute;
                    z-index: -1;
                }
            }


            &:first-child {

                .step-content {

                    &::after {
                        content: url('../images/step-line-1.svg');
                        top: 50%;
                        left: 100%;
                        transform: translateY(-50%);
                    }
                }
            }

            &:nth-child(2) {
                margin-top: 5rem;
                margin-left: auto;
            }

            &:nth-child(3) {
                margin-top: $small;

                .step-content {

                    &::after {
                        content: url('../images/step-line-2.svg');
                        top: 35%;
                        left: 115%;
                        transform: translateY(-50%);
                    }
                }
            }

            &:nth-child(4) {
                margin-top: -3.9rem;
                margin-right: auto;

                .step-content {

                    &::before {
                        content: url('../images/step-line-3.svg');
                        bottom: 105%;
                        left: 85%;
                        transform: translateX(-50%);
                    }
                }
            }

            &:nth-child(5) {
                margin-top: 5.5rem;
                margin-right: 6rem;

                .step-content {

                    &::before {
                        content: url('../images/step-line-4.svg');
                        top: 25%;
                        right: 85%;
                        transform: translateY(-50%);
                    }
                }
            }

            &:nth-child(6) {
                margin-top: 3.9rem;

                .step-content {

                    &::after {
                        content: url('../images/step-line-5.svg');
                        bottom: 15%;
                        left: 100%;
                        transform: translateY(-50%);
                    }
                }
            }

            &:nth-child(3n) {
                max-width: 100%;
            }
        }

        @media (max-width:1440px) {

            .step {
                max-width: 30%;

                &:nth-child(3) {

                    .step-content {

                        &::after {
                            left: 110%;
                        }
                    }
                }

                &:nth-child(5) {
                    margin-right: 0;

                    .step-content {

                        &::before {

                            right: 95%;
                        }
                    }
                }

                .description {
                    max-width: 100%;
                }
            }
        }

        @media (max-width:1199px) {
            gap: 2.8rem;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .step {
                width: 100%;
                max-width: 85% !important;
                margin: 0 !important;
                margin-left: 0 auto !important;

                &::before,
                &::after {
                    position: absolute;
                    z-index: -1;
                }
                
                .step-content {

                    &::before,
                    &::after {
                        display: none;
                        visibility: hidden;
                    }
                }

                &:nth-child(even) {

                    &::after {
                        content: url('../images/step-right.svg');
                        bottom: calc(100% - 40px);
                        right: 0;
                    }
                }

                &:nth-child(odd):not(:first-child) {

                    &::before {
                        content: url('../images/step-left.svg');
                        bottom: calc(100% - 40px);
                        left: 0;
                    }
                }
            }
        }

        @media (max-width:576px) {

            .step {
                max-width: 95% !important;
            }
        }
    }
}

#page-partners {

    h2.pink {
        text-align: center;
        margin: 0 auto;
    }

    .number-bloc {
        margin: 0 auto;
    }

    .partners {

        .map-container {
            width: 100%;
            padding-bottom: 50%;
            position: relative;
            margin-top: $huge;

            @media (max-width:1199px) {
                padding-bottom: 65%;
            }

            @media (max-width:991px) {
                padding-bottom: 75%;
            }

            @media (max-width:767px) {
                padding-bottom: 70vh;
            }

            
            .map {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                
                .gm-style-iw-c {
                    border: 3px solid $color-pink;
                    padding: 1rem !important;
                    max-height: fit-content !important;
                    top: .5rem;

                    @media (max-width:576px) {
                        max-width: 350px !important;
                    }
                    
                    .gm-style-iw-d {
                        padding: 0 !important;
                        overflow: auto !important;

                        @media (max-width:991px) {
                            max-height: fit-content !important;
                        }
                    }

                    .marker-content {
                        display: grid;
                        grid-template-columns: 180px auto;
                        gap: $big;
                        min-width: 470px;

                        img {
                            border-radius: 4px;
                            max-width: 100%;
                        }

                        @media (max-width:991px) {
                            grid-template-columns: 150px auto;
                            min-width: fit-content;
                        }

                        @media (max-width:576px) {
                            grid-template-columns: 100px auto;
                        }
                    }

                    .name {
                        color: $color-pink;
                    }

                    .subtitle {
                        text-transform: uppercase;
                        font-size: 14px;
                        color: $color-pink-gray;
                        margin-top: 4px;
                        display: inline-block;
                    }

                    .btn-external {
                        margin-top: $large;
                    }

                    .btn-arrow {
                        width: 25px;
                        height: auto;
                        position: absolute;
                        right: 18px;
                        bottom: 18px;
                    }
                }


                .gm-ui-hover-effect,
                .gm-style-iw-tc {
                    display: none;
                    visibility: hidden;
                }
            }

            .map-markers {
                display: none;
            }
        }

        .swiss {
            margin-top: 8rem;
            margin-bottom: 6rem;
            position: relative;

            h3.big {
                margin: 0 auto;
                margin-top: 2rem;
                text-align: center;
                max-width: 66%;

                @media (max-width:1199px) {
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }

            .illu {
                position: absolute;
                z-index: -1;
                right: -2%;
                bottom: calc(100% + 5rem);
                max-width: 20rem;

                @media (max-width:991px) {
                    display: none;
                    visibility: hidden;
                }
            }

            @media (max-width:991px) {
                margin-top: 13rem;
            }

            svg.shape {
                position: absolute;
                z-index: -1;
                top: -5%;
                left: -15%;

                @media (max-width:991px) {
                    display: none;
                    visibility: hidden;
                }
            }

            h2 {
                position: relative;

                &::before {
                    content: url('../images/arrow-left-to-right-down.svg');
                    position: absolute;
                    z-index: -1;
                    bottom: calc(100% + 2rem);
                    left: 30%;
                    transform: translateX(-50%);

                    @media (max-width:1199px) {
                        left: 45%;
                    }

                    @media (max-width:991px) {
                        content: url('../images/arrow-down-to-left-mobile.svg');
                        left: 50%;
                    }
                }
            }

            .partner-select {
                margin: 0 auto;
                margin-top: 3rem;
                width: 25vw;
                display: block;
                background-color: $color-pink-light;
                color: $color-pink;
                padding: .6rem 1rem;
                font-family: $font-base;
                border: none;
                border-radius: 4px;
                -moz-appearance:none;
                -webkit-appearance:none;
                appearance:none;
                position: relative;
                background-image: url('../../assets/images/select-arrow.svg');
                background-position-x: 97%;
                background-position-y: 50%;
                background-repeat: no-repeat;

                &.is-active {
                    background-color: darken(#F6E0EB, 15%);
                    color: white;
                    background-image: url('../../assets/images/select-arrow-active.svg');
                  }

                &:focus-visible {
                    outline: 1px solid white;
                }

                option {
                    font-size: inherit;
                    font-family: inherit;
                }

                @media (max-width:1199px) {
                    width: 30vw;
                }

                @media (max-width:991px) {
                    width: 50vw;
                }

                @media (max-width:767px) {
                    width: 100%;
                }
            }
        }

        .others {
            margin-bottom: 6rem;
            position: relative;

            .illu {
                left: -5%;
                top: 5%;

                @media (max-width:1199px) {
                    left: -15%;
                    top: -25%;
                    max-width: 13rem;
                }

                @media (max-width:767px) {
                    left: -15%;
                    top: -10%;
                    max-width: 8rem;
                }
            }

            svg.shape {
                position: absolute;
                z-index: -1;
                top: 15%;
                right: -15%;
            }

            .intro {
                margin: 0 auto;
                margin-top: 2rem;
                text-align: center;
                max-width: 66%;

                @media (max-width:1199px) {
                    max-width: 83%;
                }

                @media (max-width:767px) {
                    max-width: 100%;
                }
            }

            .other-partners {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 2.2rem;
                margin-top: $gigantic;

                @media (max-width:991px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width:767px) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }

    .videos {
        margin-bottom: 4rem;
        position: relative;

        .illu {
            top: -10%;
            right: -5%;

            @media (max-width:767px) {
                max-width: 12rem;
                top: -20%;
                right: -2%;
            }
        }

        svg.shape {
            position: absolute;
            z-index: -1;
            top: 15%;
            left: -2%;
            max-width: 27rem;
            height: auto;
        }

        h2 {
            margin-bottom: $gigantic;
        }

        @media (max-width:991px) {

            svg.shape {
                display: none;
                visibility: hidden;
            }
        }
    }
}

.partner-card {
    color: $color-text;
    display: flex;
    align-items: center;

    img {
        width: 48%;
        max-width: 300px;
        border-radius: 4px;
    }

    .content {
        background-color: white;
        border-radius: 4px;
        padding-top: .6rem;
        padding-right: $medium;
        padding-bottom: 0.5rem;
        padding-left: $big;
        margin-left: -1.9rem;
        width: calc(40% + 1.9rem);
        position: relative;
    }

    .country {
        text-transform: uppercase;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;

        .img {
            width: 1rem;
            height: 1rem;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
                height: auto;
            }

            svg {
                position: absolute;
                z-index: 1;
                width: 100%;
                height: auto;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }

    h4 {
        margin-top: 0.6rem;
        color: $color-pink;
    }

    .name {
        text-transform: uppercase;
        color: $color-pink-gray;
        font-size: 14px;
        display: inline-block;
        margin-top: 0.1rem;
    }

    .btn-arrow {
        width: 25px;
        margin-left: auto;
        background-color: transparent;
    }
}

#page-about {

    .timeline {
        margin-bottom: 6rem;

        .illu {
            position: absolute;
            right: 0;
            bottom: 88%;
            z-index: -1;

            @media (max-width:767px) {
                right: -15%;
            }

            @media (max-width:576px) {
                max-width: 11rem;
                bottom: 100%;
            }
        }

        h2 {
            text-align: center;
            margin: 0 auto;
            margin-bottom: 1rem;
        }

        .timeline-slider {
            margin-top: 1rem;
        }

        .top {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: $big;
        }

        .slides-num {
            color: $color-pink;
            margin-left: auto;
            font-weight: 400;

            .active {
                font-weight: bold;
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            position: absolute;
            bottom: 50%;
            transform: translateY(-50%);
            z-index: 1;
            width: calc(100% - 1.222rem - 1.222rem);
        }

        .btn-arrow {
            height: auto;
            background-color:  transparent !important;
            opacity: 0;
            visibility: hidden;
            background-color:  transparent !important;

            &.slick-disabled {
                opacity: 0 !important;
                visibility: hidden !important;
            }
        }

        &:hover {

            .btn-arrow {
                &.next-btn,
                &.prev-btn {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .engagments-section {
        position: relative;
        margin-bottom: 4rem;

        .illu {
            position: absolute;
            top: -4rem;
            right: -6%;
            z-index: -1;
        }

        h2, h3 {
            margin-left: $huge;

            @media (max-width:991px) {
                margin-left: 0;
            }
        }

        h2 {
            white-space: nowrap;
        }

        h3 {
            margin-top: $large;
            max-width: 66%;
        }

        .engagments {
            margin-top: $gigantic;
            position: relative;
        }

        @media (max-width:991px) {

            h3 {
                max-width: 100%;
            }
        }

        @media (max-width:767px) {

            .illu {
                max-width: 35vw;
                top: -12%;
            }
        }
    }

    .quote-section {
        margin-top: 6rem;
        z-index: 1;

        h2 {
            text-align: center;
            margin: 0 auto;
        }

        img {
            margin-top: -3rem;
            z-index: -1;
            position: relative;

            @media (max-width:991px) {
                margin-top: -2rem;
            }
        }

        .description {
            background-color: white;
            padding: $huge;
            border-radius: 4px;
            width: 83%;
            max-width: 1020px;
            margin: 0 auto;
            margin-top: -4rem;
            position: relative;
            font-size: 1.6rem;
            line-height: normal;
            font-family: $font-base;
            font-weight: 400;

            p {
                font-size: inherit !important;
                line-height: inherit !important;
            }

            strong {
                font-weight: 600;
            }

            div.frame {
                width: calc(100% - 2rem);
                height: calc(100% + 3rem);
            }

            @media (max-width:991px) {
                padding: 1.5rem;
                width: 95%;
                font-size: 1.2rem;
                margin-top: -2rem;
            }

            @media (max-width:576px) {

                svg.frame {
                    display: none;
                }
            }
        }
    }

    svg#animated-arrow-left.testimonial-arrow {
        position: absolute;
        bottom: 100%;
        left: 45%;
        transform: translateX(-50%);
        max-width: 10rem;
    }

    .testimonials-slider {
        margin-bottom: 7rem;
        margin-top: 15rem;
    }

    .testimonial {

        &::after {
            display: none ;
            visibility: hidden;
        }

        @media (max-width:991px) {

            .illu:last-of-type {
                display: none;
                visibility: hidden;
            }

            .img-container {

                .illu {
                    display: initial;
                    visibility: visible;
                }
            }
        }
    }

    .numbers {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 8rem;
        margin-bottom: 4rem;

        .illu {
            position: absolute;
            z-index: -1;
            left: -3%;
            top: 85%;

            @media (max-width:1441px) {
                top: 110%;
            }
        }

        @media (max-width:991px) {
            gap: 3rem
        }

        @media (max-width:767px) {
            gap: $massive;
            flex-direction: column;

            .illu {
                display: none;
                visibility: hidden;
            }
        }
    }
}

#page-homepage {

    .intro {
        position: relative;

        .illu {
            position: absolute;
            top: -4rem;
            right: -6%;
            z-index: -1;
        }

        h2, h3 {
            margin-left: $huge;
        }

        h3 {
            margin-top: $large;
            max-width: 66%;
        }

        .obligations {
            margin-top: $gigantic;
            position: relative;

            &::after {
                content: url('../images/arrow-down-to-left-small.svg');
                position: absolute;
                top: 102%;
                left: 45%;
                transform: translateX(-50%);
                max-width: 10rem;

                @media (max-width:991px) {
                    content: url('../images/arrow-down-to-left-small-mobile.svg');
                    top: 105%;
                }
            }
        }

        @media (max-width:991px) {

            h2, h3 {
                margin-left: 0;
            }

            h3 {
                max-width: 100%;
            }
        }

        @media (max-width:767px) {

            .illu {
                max-width: 35vw;
                top: -12%;
            }
        }
    }

    svg#animated-arrow-left.testimonial-arrow {
        display: none;
        visibility: hidden;
    }

    .shortcuts {
        position: relative;
        margin-bottom: 10rem;

        &::after {
            content: url('../images/arrow-down-to-right-small.svg');
            position: absolute;
            top: 105%;
            left: 45%;
            transform: translateX(-50%);
            max-width: 15rem;

            @media (max-width:991px) {
                content: url('../images/arrow-down-to-left-mobile.svg');
            }
        }

        svg.shape {
            position: absolute;
            z-index: -2;
            right: 0;
            &.pink {
                top: -10%;
                width: 41rem;
                height: auto;
            }

            &.beige {
                bottom: -22%;
                width: 27rem;
                height: auto;
            }
        }

        .illu {
            position: absolute;
            left: 2%;
            bottom: 99%;
        }

        .img {
            position: absolute;
            width: 100%;
            height: calc(100% - 100px);
            z-index: -1;
            overflow: hidden;
            top: $massive;
            left: 50%;
            transform: translateX(-50%);

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                transform: translate(-50%, -50%);
                object-fit: cover;
            }
        }

        h2 {
            margin-bottom: 6.6rem;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            strong {
                margin: 0 auto;
                margin-bottom: 0.5rem;
            }
        }

        .shortcuts-content {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: $medium;
            row-gap: 5.5rem;
            max-width: 1380px;
            margin: 0 auto;
        }

        @media (max-width:991px) {

            .illu {
                max-width: 33vw;
                left: -2%;
                bottom: 103%;
            }

            .shortcuts-content {
                grid-template-columns: repeat(2, 1fr);
            }

            svg.shape {
                &.beige {
                    width: 15rem;
                    bottom: -12%;
                }
            }
        }

        @media (max-width:767px) {
            margin-bottom: 12rem;
        }

        @media (max-width:576px) {

            .shortcuts-content {
                grid-template-columns: 1fr;
            }
        }
    }

    .news {
        position: relative;
        text-align: center;

        svg.shape {
            position: absolute;
            z-index: -1;
            top: 15%;
            left: -2%;
            max-width: 27rem;
            height: auto;
        }

        .illu {
            position: absolute;
            z-index: -1;
            top: -10%;
            left: -10%;
        }

        h2 {

            strong {
                margin: 0 auto;
                margin-bottom: 0.5rem;
            }
        }

        .slider-container {
            margin-top: 2rem;
        }

        @media (max-width:991px) {

            .illu {
                width: 35vw;
                top: initial;
                bottom: 75%;
            }

            svg.shape {
                display: none;
                visibility: hidden;
            }
        }

        @media (max-width:767px) {

            .illu {
                bottom: 85%;
            }
        }
    }

    .newsletter-cta {
        margin-top: 2.5rem;
        margin-bottom: $big;
    }
}
