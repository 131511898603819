

// Base


p, h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.container {
  width: 100%;
  max-width: 2060px !important;
  margin: 0 auto;
  padding: 0 70px;

  @media (max-width: 767px) {
    padding: 0 45px;
  }

  @media (max-width:576px) {
    padding: 0 24px;
  }
}

.small:not(input, select, textarea) {
  font-size: $small; //16px
  line-height: normal;

  @media (max-width: 767px) {
    font-size:$regular-mobile !important;
    line-height: normal;
  }
}

.regular,
p {
  font-size: $regular; //18px
  line-height: normal;
  font-family: $font-base;
  margin: 0;

  @media (max-width: 767px) {
    font-size: $regular !important;
    line-height: normal;
  }
}

p {
  margin-top: $medium;

  &:first-child {
    margin-top: 0;
  }
}

.wp-block-columns {
  margin-top: $medium;
  margin-bottom: 0;
}

.medium,
h6 {
  font-size: $medium !important; //20px
  line-height: normal;
  font-family: $font-base;
  font-weight: 400;

  @media (max-width: 767px) {
    font-size: $regular !important;
    line-height: normal;
  }
}

.big,
h5 {
  font-size: $big !important; //22px
  line-height: normal;
  font-family: $font-base;
  font-weight: 600;
}

.large:not(input, select, textarea),
h4 {
  font-size: $large !important; //34px
  line-height: normal;
  font-family: $font-title;
  font-weight: 600;

  @media (max-width:767px) {
    font-size: 1.5rem !important;
    line-height: normal;
  }

  @media (max-width:576px) {
    font-size: 1.2rem !important;
  }
}

.massive,
h3 {
  font-size: $massive !important; //38px
  line-height: normal;
  font-family: $font-title;
  font-weight: 600;

  @media (max-width: 767px) {
    font-size: $large !important;
    line-height: normal;
  }

  @media (max-width:576px) {
    font-size: 1.3rem !important;
  }
}

.huge,
h2 {
  font-size: $huge !important; //48px
  line-height: normal;
  font-family: $font-title;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: $massive !important;
    line-height: normal;
  }

  @media (max-width:576px) {
    font-size: $large !important;
  }
}

.gigantic,
h1 {
  font-size: $gigantic !important; //64px
  line-height: 1em;
  font-family: $font-title;
  font-weight: 700;

  @media (max-width:1199px) {
    font-size: 2.8rem !important;
    line-height: normal;
  }

  @media (max-width: 767px) {
    font-size: $huge !important;
    line-height: normal;
  }
}

.pink:not(svg) {
  
  strong {
    display: inline;
    padding: 0 $small;
    background-color: $color-pink;
    color: white;
    line-height: 1.9;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
  }
}

.btn-arrow {
  color: white;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  padding: 0;
  max-width: 1.9rem !important;
  height: auto;

  svg {
    width: 100%;
    height: auto;

    path {
      transition: all .2s linear;
    }
  }

  &:hover {

    svg {
        path:not(.arrow) {
            fill: $color-pink-dark;
        }
    }
}
}

.btn-icon:not(.socials) {
  font-size: $regular;
  line-height: normal;
  color: $color-link;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
  width: fit-content;

  svg.icon {

    path {
      transition: all .2s linear;
    }
  }

  &:hover {
    color: $color-link-hover;

    svg.icon {

      path {
        fill: $color-link-hover;
      }
    }
  }
}

.pink-title {

  strong {
    display: inline;
    padding: 0.5rem $small;
    background-color: $color-pink;
    color: white;
    line-height: 1.9;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    margin-bottom: .5rem;

    @media (max-width:991px) {
      line-height: 2;
    }
  }
}

.btn {
  font-size: $regular;
  line-height: normal;
  color: $color-pink;
  padding-bottom: .4rem;
  border-bottom: 1px solid $color-pink;

  &:hover {
    color: $color-pink-dark;
    border-color: $color-pink-dark;
  }

  &.arrow {
    font-size: $small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: .5rem;
    padding: 0;
    border-bottom: none;

    &::after {
      content: url('../images/arrow-right.svg');
    }

    &:hover {
      color: $color-pink-dark;
      gap: .8rem;
    }
  }
  &.back {
    font-size: $small;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    gap: .5rem;
    padding: 0;
    border-bottom: none;

    &::before {
      content: url('../images/arrow-left.svg');
    }

    &:hover {
      color: $color-pink-dark;
      gap: .3rem;
    }
  }
}

.btn-download {
  font-size: $regular;
  color: $color-pink;
  padding-bottom: .4rem;
  border-bottom: 1px solid $color-pink;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: .5rem;
  width: fit-content;

  &::before {
    content: url('../images/download-icon.svg');
  }

  .size {
    font-size: .8rem;
    text-transform: lowercase;
    margin-left: 0.2rem;
  }

  &:hover {
    color: $color-pink-dark;
    border-color: $color-pink-dark;
  }
}

.btn-primary {
  color: white;
  background-color: $color-pink;
  padding: .5rem $big;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 50px;
  font-size: $big;
  line-height: normal;
  font-weight: 600;
  border: none;
  font-family: inherit;
  cursor: pointer;

  &:hover {
    background-color: $color-pink-dark;
    color: white;
  }
}

.btn-external {
  font-size: 1rem;
  color: $color-pink;
  padding-bottom: .4rem;
  border-bottom: 1px solid $color-pink;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: .5rem;
  width: fit-content;

  &::before {
    content: url('../images/external-icon.svg');
  }

  &:hover {
    color: $color-pink-dark;
    border-color: $color-pink-dark;
  }
}

.btn-play {
  background-color: transparent !important;
  width: $huge;
  height: fit-content;
  padding: 0;

  svg {
    width: 100%;
    height: auto;
  }
}

#post-share {

  .social-share {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $medium;
  }

  .title {
    font-size: $small !important;
    font-family: $font-base !important;
    font-weight: 400;
    color: $color-primary;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: .4rem;
  }
}