//
// Footer Styles
// --------------------------------------------------

#footer {

	.footer-top {
		width: 100vw;
		height: auto;
		margin-bottom: -10px;
	}

	#footer-top {
		background-color: $color-beige-light;

		.container {
			position: relative;

			.raspberry {
				position: absolute;
				bottom: 0;
				left: -3%;

				@media (max-width:1440px) {
					left: -4%;
				}
			}
		}

		.content {
			display: flex;
			flex-wrap: wrap;
			column-gap: $medium;
			row-gap: $regular;
			align-items: flex-start;
			justify-content: space-between;
			padding-top: $medium;

			.address {
				width: 75%;
				display: flex;
				gap: $big;

				.novae-icon {
					position: relative;
					transform: translateY(-55%);
					width: 6.7rem;
				}

				p {
					color: $color-pink;
					font-weight: bold;
				}

				@media (max-width:991px) {

					.novae-icon {
						width: 4rem;
					}
				}
			}

			.team-button {
				text-align: center;
				color: $color-text;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				width: fit-content;
				max-width: 21%;
				padding: $big $medium;
				white-space: nowrap;

				svg.vector {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 100%;
					height: auto;
					transform: translate(-50%, -50%);
					
					path {
						transition: all .2s linear;
					}
				}

				&:hover {
					color: $color-pink-dark;

					svg.vector {

						path {
							stroke: $color-pink-dark;
						}
					}
				}
			}
			
			.contact {
				width: 100%;
				display: flex;
				flex-wrap: wrap;
				row-gap: $huge;
				margin-left: calc(6.7rem + 1.222rem);
				margin-top: -5%;

				.btn-icon.mail,
				.btn-icon.socials {
					margin-left: $large;
				}

				.btn-icon.socials {
					display: flex;
					align-items: center;
					justify-content: flex-start;

					ul {
						padding: 0;
						margin: 0;
						margin-left: 1rem;
						list-style: none;
						display: flex;
						align-items: center;
						justify-content: flex-start;
						flex-wrap: wrap;
						gap: $medium;
					}
				}

				.bottom {
					width: 100%;
					display: flex;
					align-items: center;
					flex-wrap: wrap;

					.team-button {
						display: none;
						visibility: hidden;
					}
					
					.btn-icon.faq {
						margin-left: auto;
					}
					
					.btn-icon.press {
						margin-left: $regular;
					}

					.carbon-badge {
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						margin-top: 1.5rem;
						margin-bottom: 1rem;

						#wcb #wcb_a, #wcb #wcb_g {
							border-color: $color-primary !important;
							color: $color-text !important;
						}

						#wcb.wcb-d #wcb_a {
							background-color: $color-primary !important;
							color: white !important;
						}

						#wcb.wcb-d #wcb_2 {
							color: $color-text !important
						}
					}
					
					@media (max-width:1199px) {
						flex-wrap: wrap;
						align-items: flex-start;
						order: 4;

						.newsletter {
							order: 4;
							width: 100%;
							margin-top: $gigantic;
						}

						.btn-icon.faq {
							margin-left: 0;
						}

						.carbon-badge {
							order: 5;
						}
					}

					@media (max-width:991px) {

						.newsletter {
							flex-wrap: wrap;
							gap: $small;

							p, .gform_wrapper {
								width: 100%;
							}
						}
					}
				}

				.newsletter {
					width: 58%;
					display: flex;
					align-items: center;
					justify-content: flex-start;

					p {
						margin-right: $regular;
						margin-top: 0;
						width: 30%;
					}

					.gform_wrapper {
						width: 70%;

						.gform_body {

							.gfield {
								font-size: 15px;
							}

							input {
								padding: 8px 15px;
							}
						}

						.gform_footer {

							input {
								width: 37px;
								max-width: 37px;
							}
						}
					}
				}

				@media (max-width:1199px) {
					
					.btn-icon.socials {
						order: 1;
						margin-left: 0;
						margin-right: $large;
						width: 100%;
					}

					.btn-icon.phone {
						order: 2;
					}

					.btn-icon.btn-icon.mail {
						order: 3;
					}
					
				}

				@media (max-width:991px) {
					margin-left: calc(4rem + 1.222rem);
					row-gap: $big;
					margin-top: 0;

					.btn-icon.socials {
						margin-bottom: $large;
					}
				}
			}

			@media (max-width:991px) {

				.address {
					width: 40%;
				}

				.team-button {
					max-width: 40%;
				}
			}

			@media (max-width:767px) {

				.btn-icon {

					.icon-container {
						width: 55px;
						height: auto;
					}
				}

				.address {
					width: auto;
					margin-right: $big;
					margin-bottom: -10%;
				}

				.team-button {
					display: none;
					visibility: hidden;
				}

				.contact {
					margin-left: 0;
					flex-direction: column;

					.btn-icon {
						margin-right: 0;
						margin-left: 0 !important;
					}

					.btn-icon.socials {
						margin-left: calc(4rem + 1.222rem) !important;
					}

					.bottom {
						flex-direction: column;

						.team-button {
							display: initial;
							visibility: visible;
							order: 3;
							margin-top: $gigantic;
							max-width: fit-content;
						}

						.btn-icon {
							&:last-child {
								margin-top: $big;
							}
						}
					}
				}
			}
		}
	}

	#footer-bottom {
		font-size: 14px;
		background-color: $color-pink;
		color: white;
		padding: $small 0;

		.container {
			display: flex;
			align-items: center;
			justify-content: space-between;

			@media (max-width:991px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: $small;
			}
		}

		p {
			font-size: inherit !important;
			line-height: normal;
			margin: 0;
		}

		a {
			color: inherit;
		}

		.left-side,
		.right-side {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			gap: $small;

			p {
				
				a {
					display: flex;
					align-items: center;
				}
			}

			img {
				margin-left: $small;
			}

			span {
				opacity: .5;
			}

			@media (max-width:767px) {
				flex-wrap: wrap;
			}
		}

		.right-side {
			@media (max-width:576px) {
				align-items: flex-start;

				span {
					display: none;
					visibility: hidden;
				}
			}
		}
	}

	.powered {
		position: relative;
		color: inherit;
		&:hover {
			color: inherit;
			text-decoration: none;
			#logo-alt-footer-trois {
				fill: white;
				transform: scale(1.2);
			}
			#logo-alt-footer-deux {
				fill: white;
				transform: scale(1.2);
			}
			#logo-alt-footer-un {
				fill: white;
				transform: scale(1.2);
			}
			&:after {
				width: 90px;
			}
		}
		svg {
		    overflow: visible;
			width: 50px;
			height: 24px;
			margin: 0 0 0 5px;
			position: relative;
			top: -2px;
			vertical-align: middle;
			width: 34px;
			#logo-alt-footer-trois {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-deux {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
				transform: scale(1);
				transform-origin: center;
			}
			#logo-alt-footer-un {
				fill: transparent;
				transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
				transform: scale(1);
				transform-origin: center;
			}
		}
		&:after {
			display: block;
		    content: '';
		    position: absolute;
		    bottom: -2px;
		    left: 0;
		    width: 0px;
		    height: 2px;
		    background-color: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}
}
